.nav {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12%;
  position: relative;
}

.nav .logo a {
  font-size: 45px;
  font-weight: 800;
  background: linear-gradient(90deg , #ffdb39 , #f78502);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.nav ul li {
  display: inline-block;
  margin: 0 12px;
}

.nav ul li a {
  font-size: 22px;
  font-weight: 500;

  color: #ddd;

  position: relative;
}

.nav ul li a::after{
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;

  height: 2px;
  width: 0%;

  background-color: var(--primary-color);
  transition: 0.6s;
}

.nav ul li:hover a::after{
  width: 100%;
}

.nav ul li:hover a{
  color: var(--primary-color);
}

#bars {
  height: 45px;
  width: 45px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); 

  cursor: pointer;
  transition: 0.4s ease;
  display: none;
}

#bars:hover {
  background: rgba(255, 255, 255, 0.2); 
  transform: scale(1.05);
}

@media screen and (max-width:900px) {
  #bars {
    display: flex;
  }

  .nav ul {
    position: absolute;
    top: 100%;
    left: -100%;
    width: 100%;

    background: rgba(30, 30, 30, 0.4);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 9999;
    transition: .6s;
  }

  .nav ul li {
    display: block;
    padding: 30px 0;
    padding-left: 12%;
    border-bottom: 1px solid #dddddd18;
  }
  
  .nav  .openMenu {
    left: 0;
  }
}