.headerContainer {
    display: flex;
    align-items: center;
    padding: 0 12%;
    width: 100%;
    min-height: 80vh;
}

.headerContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.headerContent h5 {
    font-size: 22px;
    letter-spacing: 3;
    color: var(--primary-color);
}

.headerContent h6 {
    font-size: 22px;
    letter-spacing: 3;
    font-style: italic;
    color: var(--primary-color);
}

.headerContent h1 {
    font-size: 100px;
    line-height: 120px;
    background: linear-gradient(90deg , var(--primary-color) , #ffffff 50%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}  

.headerContent p {
    color: #fff;
    font-size: 22px;
}

.headerContent .social {
    display: flex;
    gap: 20px;
}

.headerContent .social i {
    font-size: 22px;
    height: 50px;
    width: 50px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    transition: .6s;
}

.headerContent .social i:hover {
    background-color: var(--primary-color);
    color: #1e1e1e;
}

.headerImage {
    width: 100%;
    height: auto;
    max-width: 580px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-shrink: 0;
    z-index: 3;
}

.headerImage img {
    width: 580px;
    height: 580px;
    position: relative;
    z-index: 2;
    border-radius: 50%;
}

.borderAnimation {
    position: absolute;
    height: 582px;
    width: 582px;

    top: 50%;
    left: 50%;
    z-index: 1;

    transform: translate(-50% , -50%);

    &::after ,
    &::before{
        content: '';
        position: absolute;
        inset: -3px;
        background-image: conic-gradient(
            from var(--angle),
            transparent 50% ,
            var(--primary-color)
        );

        border-radius: 50%;

        animation: spin 2s infinite linear;
    }

    &::before {
        filter: blur(50px);
        opacity: 1;
    }
}

@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

@keyframes spin {
    to {
        --angle: 360deg;
    }
}

@media screen and (max-width:1400px) {
    .headerImage img {
        height: 500px;
        width: 500px;
    }

    .borderAnimation {
        height: 502px;
        width: 502px;
    }
}

@media screen and (max-width:1200px) {
    .headerContainer { 
        flex-direction: column-reverse;
        align-items: center;
    }

    .headerContainer .headerContent {
        width: 100%;
        margin-top: 80px;
        text-align: center;
        align-items: center;
        margin-bottom: 50px; 
    }
}

@media screen and (max-width:768px) {
    .headerImage img {
        width: 100%;
        height: 100%;
    }

    .headerImage {
        height: 102%;
        width: 102%;
    }

    .borderAnimation {
        height: 102%;
        width: 102%;
    
        &::before {
            filter: none;
            opacity: 0.7;
        }
    }
}