@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100..700;1,100..700&display=swap');

*{
    padding: 0;
    margin: 0;

    box-sizing: border-box;

    text-decoration: none;
    list-style: none;

    font-family: 'IBM Plex sans';
}

:root {
    --primary-color: #ccffee;
}

body {
    background-image: url(assets/body-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;

    z-index: 1;
}

.header-wrapper {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;

    background: linear-gradient(0deg , rgb(2, 89, 70) 0%, 
    rgba(16,16,16) 100%);

    overflow: hidden;
}

button {
    height: 40px;
    width: 140px;

    border: 1px solid var(--primary-color);
    font-weight: 500;

    background-color: #1e1e1e;
    cursor: pointer;

    color: var(--primary-color);
    font-size: 18px;

    position: relative;

    overflow: hidden;
}

button::after {
    content: "Download";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    background: linear-gradient(90deg , var(--primary-color) , #ffffff 80%);

    padding: 10px 0;

    color: #0d4229;

    font-size: 15px !important;

    transform: translateX(130px);

    transition: .6s;
}

button:hover:after {
    transform: translateX(0);
}